<template>
  <app-overlay loading-variable="modalOverlay">
    <show-image-modal ref="showImageModal" />
    <searchable-table class="border mb-50" :items="items" :fields="fields">
      <template #actions="{ item }">
        <app-dropdown v-if="training.isStarted && windowWidth < 480">
          <b-dropdown-item @click="createDigitalCertificate(item)">
            <feather-icon :icon="item.isDigitalWsCertificateUploaded ? 'FileTextIcon' : 'PlusIcon'" />
            <span class="ml-50">Dijital Sertifika</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="item.isOriginalWsCertificateUploaded" @click="checkIsOriginalCertificateUploaded(item)">
            <feather-icon icon="FileTextIcon" />
            <span class="ml-50">Sertifika Aslı</span>
          </b-dropdown-item>
        </app-dropdown>
        <div v-else class="d-flex gap-3">
          <app-button
            v-if="training.isStarted"
            @click="createDigitalCertificate(item)"
            text="Dijital Sertifika"
            :icon="item.isDigitalWsCertificateUploaded ? 'FileTextIcon' : 'PlusIcon'"
          />
          <app-button
            v-if="training.isStarted && item.isOriginalWsCertificateUploaded"
            @click="checkIsOriginalCertificateUploaded(item)"
            text="Sertifika Aslı"
            icon="FileTextIcon"
          />
        </div>
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
import ShowImageModal from "./OriginalParticipationForm/ShowImageModal.vue";
export default {
  components: { ShowImageModal },
  data() {
    return {
      fields: [
        { key: "employee.fullname", label: "Ad Soyad" },
        { key: "employee.duty", label: "Unvan" },
        { key: "actions", label: "İşlemler" },
      ],
    };
  },
  computed: {
    items() {
      return this.training?.employees ?? [];
    },
    training() {
      return this.$store.getters.appModalItem;
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
  },
  methods: {
    createDigitalCertificate(item) {
      this.$axios
        .get("/trainings/employee-certificate", {
          params: {
            trainingId: this.training?.id,
            employeeId: item?.employee?._id,
          },
          loading: "modal",
          responseType: "blob",
        })
        .then((result) => {
          if (!item.isDigitalWsCertificateUploaded) {
            const index = this.training?.employees?.findIndex((x) => x._id === item._id);
            this.training.employees[index].isDigitalWsCertificateUploaded = true;
            this.$store.commit("setAppModalOptions", { item: this.training });
          }
          const url = window.URL.createObjectURL(new Blob([result.data], { type: "application/pdf" }));
          const newWindow = window.open(url, "_blank");
          if (!newWindow) {
            alert("Pop-up engelleyiciniz aktif durumdadır. Dökümanı görüntüleyebilmek için lütfen engelleyiciyi kapatınız.");
          }
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message ?? null });
        });
    },
    checkIsOriginalCertificateUploaded(item) {
      if (item?.isOriginalWsCertificateUploaded) {
        if (item?.image) {
          this.$refs.showImageModal.selectedImage = item.image;
          this.$refs.showImageModal.show();
        } else {
          this.$axios
            .get("/trainings/employee-certificate/original", {
              params: { trainingId: this?.training?._id, employeeId: item?.employee?._id },
              loading: "modal",
              responseType: "arraybuffer",
            })
            .then((result) => {
              const base64Image = btoa(new Uint8Array(result.data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
              this.$refs.showImageModal.selectedImage = `data:image/png;base64,${base64Image}`;
              this.$refs.showImageModal.show();
              item.image = `data:image/png;base64,${base64Image}`;
            })
            .catch((err) => {
              this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
            });
        }
      }
    },
  },
};
</script>

<style></style>

<template>
  <app-overlay loading-variable="modalOverlay">
    <show-image-modal ref="showFormImage" />
    <searchable-table :items="items" :fields="fields">
      <template #actions="{ item }">
        <div class="d-flex gap-5">
          <app-icon-button @click="showImage(item)" icon="EyeIcon" variant="gradient-danger" />
        </div>
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
import ShowImageModal from "./ShowImageModal.vue";
export default {
  components: { ShowImageModal },
  data() {
    return {
      fields: [
        { key: "createdAt", label: "Oluşturulma Tarihi", formatDateTime: true },
        { key: "actions", label: "İşlemler" },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.getters.appModalItem?.originalParticipationForms ?? [];
    },
    item() {
      return this.$store.getters.appModalItem;
    },
  },
  methods: {
    showImage(item) {
      if (item?.image) {
        this.$refs.showFormImage.selectedImage = item.image;
        this.$refs.showFormImage.show();
      } else {
        this.$axios
          .get(`/trainings/${this.item._id}/get-original-form`, { params: { fileId: item?._id }, loading: "modal", responseType: "arraybuffer" })
          .then((result) => {
            const base64Image = btoa(new Uint8Array(result.data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
            item.image = `data:image/png;base64,${base64Image}`;
            this.$refs.showFormImage.selectedImage = `data:image/png;base64,${base64Image}`;
            this.$refs.showFormImage.show();
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
          });
      }
    },
  },
};
</script>

<style></style>

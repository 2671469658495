<template>
  <app-modal
    id="originalParticipationFormImage"
    ref="originalParticipationFormImageModal"
    @hide="hide"
    noBodyGap
    size="image"
  >
    <div class="p-25">
      <b-img fluid-grow class="fit-image-to-screen" :src="selectedImage" />
    </div>
  </app-modal>
</template>

<script>
export default {
  data() {
    return {
      selectedImage: null,
    };
  },
  methods: {
    hide() {
      setTimeout(() => {
        this.selectedImage = null;
      }, 300);
    },
    show() {
      this.$refs.originalParticipationFormImageModal.show();
    },
  },
};
</script>

<!-- <style>
@media only screen and (min-width: 1300px) {
  .fit-image {
    object-fit: contain;
    height: 86vh;
  }
}
</style> -->
